import React from 'react';

import styled from 'styled-components';

import Layout from '../components/layout';
import OriginalMembers from '../img/original-members.png';

const Container = styled.div`
  padding: 2rem;
  text-align: center;
  width: 100%;

  @media (min-width: 768px) {
    text-align: left;
    padding: 4rem;
  }
`;

const StyledOriginalMembers = styled.div`
  margin-bottom: 2rem;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`;
export default function History() {
  return (
    <Layout>
      <Container>
        <h4>History of Abraham H. Howland Jr. Lodge, A.F. & A.M.</h4>
        <p>
          In 1914, a number of local masons who had been considering the need
          for another lodge in New Bedford decided it was time to take definite
          action in proposing its formation. Star-in-the-East and Eureka Lodges
          had grown to the extent that the group felt there was room in the city
          for the proposed new lodge.
        </p>
        <p>
          At the suggestion of Bro. Zacheus C. Dunham, a member of the
          Star-in-the-East Lodge, a meeting was called of those interested in
          the movement. After a full discussion and careful consideration of the
          petition for a Dispensation for a new Lodge, it was voted to bring the
          proposal before the city&apos;s two lodges. As expected, there was
          strong opposition, but the petitioners finally convinced both bodies
          that the formation of a new Lodge was warranted for a number of well
          founded reasons. With assurance that the petitioners understood fully
          the financial and administrative requirements, responsibilities and
          problems involved in the operation of such an organization,
          Star-in-the-East and Eureka Lodges agreed to approve the action.
        </p>
        <p>
          Following approval, a meeting of the petitioners was held and
          application for a Dispensation for a new Lodge of Ancient Free and
          Accepted Masons was made to the Grand Lodge of Massachusetts.
        </p>
        <p>
          At a quarterly communication of Grand Lodge, held in March 1915, a
          Dispensation was granted.
        </p>
        <h5>Organizational Meeting</h5>
        <p>
          On June 8, 1915 a meeting was held in the old Masonic Hall (quarters
          prior to present Masonic Temple). The meeting was called to order by
          District Deputy Grand Master George B. Luther for organization Under
          Dispensation (U.D.) granted by M.W. Melvin Maynard Johnson, Grand
          Master of the Most Worshipful Grand Lodge of Masons in Massachusetts.
        </p>
        <p>
          In addition to D.D.G.M. George B. Luther of the Thirtieth Masonic
          District, the following brethren were present:
        </p>
        <StyledOriginalMembers>
          <img src={OriginalMembers} alt="Original Members" />
        </StyledOriginalMembers>
        <p>
          <em>
            <strong>Front Row:</strong> Seth J. Besse, Zac. C. Dunham, Elton S.
            Wilde, Wm. Stitt, H.A. Linfield, Channing Wilde, L.C. Bauldry, H.I.
            Wordell.
          </em>
          <br />
          <em>
            <strong>Back Row:</strong> Geo. H Nye, A.W. Milliken, John L. Weeks,
            Wm H. Wagner, Richmond Wood, Richard Knowles, Wm. F. Kern, Jr.
          </em>
        </p>
      </Container>
    </Layout>
  );
}
